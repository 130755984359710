import React, {useState} from 'react'
import {Row, Col, ListGroup, ListGroupItem} from 'reactstrap'
import styled from 'styled-components'
import Page from 'ui/page'
import ProductCard from 'containers/list/products/product_card'
import { useTranslation } from 'react-i18next'
import ReactCarousel from 'react-multi-carousel'
import time from 'i18n/time'
import cn from 'classnames'
import Modal from 'ui/modal'
import parse from 'react-html-parser'
import 'react-multi-carousel/lib/styles.css'
import WeatherWidget from 'containers/home/weather_widget'

const Category = styled(Col)`
  cursor: pointer;

  &:hover {
    &:after {
      content: "";
      border-radius: 5px;
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: var(--primary);
      opacity: 0.4;
      z-index: 0;
    }
  }

  @media (min-width: 1200px) {

    flex: 0 0 ${props => props.fullSize ? '10%' : '14%'} !important;
    max-width: 10%% !important;
  }
`

const CategoryTitle = styled.div`
  padding: inherit;
  position: absolute;
  padding-bottom: 0.65em;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  text-align: center;
  font-size: 0.68em;
  font-weight: bold;
`

const grid = {
  xl: {
    breakpoint: { max: 8000, min: 1200 },
    items: 5
  },
  lg: {
    breakpoint: { max: 1200, min: 992 },
    items: 4
  },
  md: {
    breakpoint: { max: 992, min: 768 },
    items: 3
  },
  sm: {
    breakpoint: { max: 768, min: 576 },
    items: 2
  },
  xs: {
    breakpoint: { max: 576, min: 0 },
    items: 1
  }
}

const Home = (props) => {
  const {destination_id, categories, best_sellers, favorites, bulletin, user, urls, locale} = props
  const showBulletin = bulletin.pagination.total_items >= 1 && user.user_data.user.sale_channel == 'b2b'
  const {i18n} = useTranslation()

  const CategoryIcons = () => {
    return categories.map((props) => {
      const {id, thumbnail, slug, title} = props

      const url = `${urls.products}?category=${id}`

      return (
        <Category key={slug} xs="6" sm="4" md="2" className="p-1" fullSize={!showBulletin} onClick={() => {
          window.location.href = url
        }}>
          <img src={thumbnail} width="100%" className="rounded"></img>
          <CategoryTitle className="category-icon-title text-white text-uppercase">{title}</CategoryTitle>
        </Category>
      )
    })
  }

  const Bulletin = () => {
    return (
      <ListGroup className="mt-1">
        <ListGroupItem className="bg-dark text-white p-1 px-3">
          <strong>
            <i className="fa fa-newspaper mr-2"/>
            {i18n.t('bulletin.title')}
          </strong>
        </ListGroupItem>
        {bulletin.records.slice(0,1).map((r) => {
          const [modal, setModal] = useState(false)
          const toggle = () => setModal(!modal)

          return (
            <ListGroupItem key={r.id} className="rounded-0">
              <Modal isOpen={modal} toggle={toggle} title={r.tiyle} width={1250}>
                {parse(r.content)}
              </Modal>
              <h6 className="m-0" onClick={() => toggle()}>
                <a className="text-primary" style={{cursor: 'pointer'}}>
                  {r.title}
                </a>
              </h6>
              <small className="mb-2"><i>{time(r.created_at).format('LL')}</i></small>
              <p className="mb-1">{r.excerpt}</p>
            </ListGroupItem>
          )
        })}
        <ListGroupItem className="text-right bg-light p-1 px-3">
          <a href={urls.bulletin}><small><i>{i18n.t('format:actions.see_more')}</i></small></a>
        </ListGroupItem>
      </ListGroup>
    )
  }

  const userWithoutWeatherWidget = [523, 3787]

  return (
    <Page {...props}>
      <Row>
        {showBulletin &&
          <Col xs="12" md="4">
            <Row>
              <Col>
                <Bulletin/>
              </Col>
            </Row>
            <Row>
              <Col className="mt-3 d-md-none d-lg-block">
                {!userWithoutWeatherWidget.includes(user.id) &&
                  <WeatherWidget destination={destination_id} locale={locale}/>
                }
              </Col>
            </Row>
          </Col>
        }
        <Col xs="12" md={cn(showBulletin ? '8' : '12')}>
          <Row className="row-cols">
            <CategoryIcons/>
          </Row>
        </Col>
      </Row>
      {best_sellers && best_sellers.products.length > 0 &&
        <Row>
          <Col className="mt-4 p-0">
            <h2 className="px-2">{i18n.t('home.best_sellers')}</h2>
            <ReactCarousel
              responsive={grid}
              itemClass="col card-wrapper px-2 mb-3"
              infinite={true}
              autoPlay
              autoPlaySpeed={5000}
              slidesToSlide={2}
              pauseOnHover
            >
              {best_sellers.products.map((product, key) =>
                <ProductCard
                  {...product}
                  hideWrapper
                  height={'165'}
                  key={key}
                  url={urls.service.replace(/ *\:[^)]*\: */g, product.slug)}
                />
              )}
            </ReactCarousel>
          </Col>
        </Row>
      }
      {favorites && favorites.products.length > 0 &&
        <Row>
          <Col className="mt-4 p-0">
            <h2 className="px-2">{i18n.t('home.favorites')}</h2>
            <ReactCarousel
              responsive={grid}
              itemClass="col card-wrapper px-2 mb-3"
              infinite={true}
              slidesToSlide={2}
            >
              {favorites.products.map((product, key) =>
                <ProductCard
                  {...product}
                  hideWrapper
                  height={'165'}
                  key={key}
                  url={urls.service.replace(/ *\:[^)]*\: */g, product.slug)}
                />
              )}
            </ReactCarousel>
          </Col>
        </Row>
      }
    </Page>
  )
}

export default Home
